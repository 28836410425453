<template>
    <section>
        <div ref="refFilter">
            <div class="filter-box" v-for="(group, groupIndex) in filter" :key="groupIndex">
                <template v-if="group.type === typeShopDistrict">
                    <div class="wrapper-box">
                        <div class="title" @click="toggleViewMore(group)">
                            {{ group.title }}
                            <button class="btn-view-more" v-if="districtOption.length > maxItemShowMore">
                                <b-icon icon="chevron-up" v-if="group.isShowMore"></b-icon>
                                <b-icon icon="chevron-down" v-else></b-icon>
                            </button>
                        </div>
                        <div class="dropdown-list">
                            <dropdown
                                v-model="selectedProvince"
                                placeholder="-- เลือกจังหวัด --"
                                @change="changeProvince($event)"
                                :classes="{ input: [ 'input-option' ] }"
                                :options="getProvinceOption()">
                            </dropdown>
                        </div>

                        <div class="no-selection mt-2" v-if="!selectedProvince">
                            <b-icon icon="clipboard"></b-icon>
                        </div>
                    
                        <div class="checkbox-list mt-2" v-if="selectedProvince">
                            <div class="item" v-for="(item, itemIndex) in getDisplayList(districtOption, group)" :key="itemIndex">
                                <checkbox
                                    v-model="item.isSelect"
                                    @afterTrigger="changeProvinceCheckbox($event, item)">
                                    <span v-html="item.text"></span>
                                </checkbox>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="group.type !== typeShopProvince && group.type !== typeShopDistrict">
                    <div class="wrapper-box">
                        <div class="title" @click="toggleViewMore(group)">
                            {{ group.title }}
                            <button class="btn-view-more" v-if="group.item.length > maxItemShowMore && !group.isHideExpand">
                                <b-icon icon="caret-up-fill" v-if="group.isShowMore"></b-icon>
                                <b-icon icon="caret-down-fill" v-else></b-icon>
                            </button>
                        </div>
                        <div class="checkbox-list">
                            <div class="item" v-for="(item, itemIndex) in getDisplayList(group.item, group)" :key="itemIndex">
                                <checkbox
                                    v-model="item.isSelect"
                                    @afterTrigger="changeCheckbox()">
                                    <span v-html="item.text"></span>
                                </checkbox>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
import Helper from '@/services/helper';

export default {
    data() {
        return {
            typeShopProvince: 'shopProvince',
            typeShopDistrict: 'shopDistrict',
            filter: null,
            selectedProvince: null,
            provinceOption: [],
            districtOption: [],
            maxItemShowMore: 6
        };
    },
    props: {
        filterOptions: Array,
        tagSelections: Array,
        filterBy: {
            type: String,
            required: false,
            default: ''
        },
        initProvince: String
    },
    watch: {
        filterOptions() {
            this.initOptionField();
        }
    },
    mounted() {
        this.initOptionField();
        
        // Set Province Selected
        this.selectedProvince = this.initProvince;
    },
    methods: {
        changeCheckbox() {
            // Prevent delay change result input
            setTimeout(() => {
                this.$emit('filterResult', { filter: this.filter });

                // Remember filter
                if (this.filterBy === 'product') {
                    this.setupFilterHistory();
                }
            });
        },
        changeProvinceCheckbox(value, item) {
            // Prevent delay change result input
            setTimeout(() => {
                const filterIndex = this.filter.findIndex(x => x.type === this.typeShopDistrict);
                const itemIndex = this.filter[filterIndex].item.findIndex(x => x.value === item.value);
                
                this.filter[filterIndex].item[itemIndex].isSelect = value;

                this.changeCheckbox();
            });
        },
        setupFilterHistory() {
            const filterSelection = [];

            this.filter.forEach((group) => {
                const type = group.type;

                group.item.forEach((item) => {
                    if (item.isSelect) {
                        filterSelection.push({
                            type: type,
                            value: item.value
                        });
                    }
                });
            });

            const searchPage = Helper.getProductSearchPageName();
            const filterStorage = {
                searchPage: searchPage === 'brand' ? location.pathname : searchPage,
                querystring: window.location.search,
                filter: filterSelection
            };

            const filter = window.btoa(encodeURIComponent(JSON.stringify(filterStorage)));
            localStorage.filter = filter;

            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);

            params.set('filter', 'true');
            url.search = params.toString();

            history.pushState({}, '', url.toString());
        },
        getProvinceOption() {
            const options = [];

            this.provinceOption.forEach((item) => {
                options.push({
                    id: item.province,
                    name: item.province
                });
            });

            return options;
        },
        changeProvince(value) {
            if (value) {
                this.selectedProvince = value;

                const filterIndex = this.filter.findIndex(x => x.type === this.typeShopProvince);

                // Clear filter province
                this.filter[filterIndex].item.map(x => x.isSelect = false);

                // Update filter province
                const itemIndex = this.filter[filterIndex].item.findIndex(x => x.value === value);
                this.filter[filterIndex].item[itemIndex].isSelect = true;

                // Trigger update
                this.changeCheckbox();
            } else {
                this.selectedProvince = null;
            }

            // Generate option
            this.districtOption = this.getDistrictOptions();
        },
        getDistrictOptions() {
            const options = [];
            const provinceData = this.provinceOption.find(x => x.province === this.selectedProvince);

            if (provinceData) {
                provinceData.district.forEach((item) => {
                    const tag = this.tagSelections?.find(x => x.value === item.value);

                    options.push({
                        text: item.text,
                        value: item.value,
                        isSelect: tag ? true : false
                    });
                });   
            }

            const filter = this.filter.find(x => x.type === this.typeShopDistrict);
            filter.item = options;

            return options;
        },
        readSavedFilter() {
            const filterStorage = localStorage.filter ? localStorage.filter : '';
            const isIncludeSavedFilter = Helper.getQueryString('filter');
			
			if (filterStorage && isIncludeSavedFilter === 'true') {
				return JSON.parse(decodeURIComponent(atob(filterStorage)));
			}
            
            return '';
        },
        initOptionField() {
            const filter = [];

            this.filterOptions.forEach((group) => {
                let hasLocationFilter = false;
                let data = {
                    title: group.title,
                    type: group.type,
                    item: [],
                    isShowMore: false,
                    isHideExpand: group.isHideExpand
                };

                group.items.forEach((item) => {
                    if (group.type === this.typeShopDistrict) {
                        this.provinceOption.push({
                            province: item.province,
                            district: item.district
                        });

                        hasLocationFilter = true;
                    } else {
                        const tag = this.tagSelections?.find(x => x.value === item.value && x.type == group.type);

                        data.item.push({
                            text: item.text,
                            value: item.value,
                            isSelect: tag ? true : false
                        });
                    }
                });

                filter.push(data);

                // Add province filter
                if (hasLocationFilter) {
                    data = {
                        title: 'จังหวัด',
                        type: this.typeShopProvince,
                        item: [],
                        isShowMore: false
                    };

                    this.provinceOption.forEach((item) => {
                        data.item.push({
                            text: item.province,
                            value: item.province,
                            isSelect: false
                        });
                    });

                    filter.push(data);
                }
            });

            this.filter = filter;
        },
        updateFilterSelection(tags) {
            this.filter.forEach((group) => {
                group.item.forEach((item) => {
                    const tag = tags?.find(x => x.value === item.value);

                    if (tag) {
                        item.isSelect = true;
                    } else {
                        item.isSelect = false;
                    }
                });
            });

            this.$emit('filterResult', { filter: this.filter });
        },
        getDisplayList(list, group) {
            if (list.length > this.maxItemShowMore && !group.isShowMore && !group.isHideExpand) {
                return list.slice(0, this.maxItemShowMore);
            } else {
                return list;
            }
        },
        toggleViewMore(group) {
            group.isShowMore = !group.isShowMore;
        },
        resetFilter() {
            this.filter.forEach((group) => {
                group.item.forEach((item) => {
                    item.isSelect = false;
                });
            });

            this.$emit('filterResult', { filter: this.filter });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.filter-box {
    .wrapper-box {
        padding: 10px;
        margin-top: 10px;
        background: #fff;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        @include borderRadius(10px);

        @media only screen and (max-width: $screenExtraLarge) {
            padding: 0;
            padding-bottom: 10px;
            border-right: none;
            border-bottom: 1px dashed #ccc;
            @include borderRadius(0);
        }
    }
}

.title {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 12px;

    &:after {
        margin-top: 5px;
        content: "";
        display: block;
        width: 60px;
        height: 1px;
        border-bottom: 2px solid #ffde3a;
    }

    .btn-view-more {
        padding: 0;
        margin-left: 5px;
        color: #666;
        border: none;
        background: none;
        outline: none;
    }
}

.checkbox-list {
    margin-top: 5px;

    .item {
        font-size: 13px;
        margin-bottom: 2px;

        @media only screen and (max-width: $screenExtraLarge) {
            font-size: 12px;
        }
    }
}

.no-selection {
    padding: 60px 0;
    text-align: center;
    border: 1px dashed #ddd;

    .bi {
        opacity: 0.5;
        font-size: 24px;
        color: #999;
    }
}

::v-deep .checkbox-list {
    .item {
        img {
            height: 28px;
        }
    }
}

::v-deep .checkbox-control {
    .checkbox-button {
        width: 16px;
        height: 16px;
    }

    .checkbox-label {
        margin-left: 6px;
    }
}

::v-deep .form-option {
    .input-option {
        font-size: 13px;
        padding: 4px 10px;
        border-color: #999;
    }

    .dropdown {
        .dropdown-wrapper {
            max-height: 150px;

            span {
                font-size: 13px;
            }
        }
    }
}
</style>